export default [
  {
    title: 'Tableau de bord',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Utilisateurs',
    route: 'user',
    icon: 'UsersIcon',
  },


  {
    title: 'Tickets',
    route: 'ticket',
    icon: 'MessageCircleIcon',
  },

  {
    title: 'Parametres',
    route: 'parametre',
    icon: 'SettingsIcon',
  },

  {
    title: 'Roles',
    icon: 'LockIcon',
    route: 'role',
  },

  {
    title: 'Permissions',
    route: 'updateRole',
    icon: 'KeyIcon',
  },

    {
    title: 'Modules',
    route: 'module',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Blog',
    route: 'blog',
    icon: 'TypeIcon',
  },
  {
    title: 'Newsletters',
    route: 'newsletter',
    icon: 'MailIcon',
  },
  {
    title: 'Categories',
    route: 'category',
    icon: 'BoxIcon',
  },
  


]
